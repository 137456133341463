// @ts-strict-ignore
import { ReactNode, useContext } from "react";
import styled from "styled-components";

import { DocumenatationTrigger } from "components/layout/DocumenatationTrigger/DocumenatationTrigger";
import { WidgetFrameTitleContext } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleContext";
import { WidgetFrameTitleInformationIcon } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationIcon";
import { WidgetFrameTitleInformationTooltip } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationTooltip";
import { WidgetFrameTitleInformationTooltipLastImport } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationTooltipLastImport";
import { TOOLTIP_CLASS } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/widget-frame-title-information.consts";
import { dayjs } from "components/ui/DatePicker/date.utils";
import { DataSourceName, useDataSource } from "hooks/useDataSource";

const WidgetFrameTitleInformationStyled = styled.div`
    position: relative;

    line-height: 1.5rem;

    & button {
        padding: 0;

        background: none;
        border: none;

        &:hover {
            & + .${TOOLTIP_CLASS} {
                display: flex;

                opacity: 1;
            }
        }
    }

    @media print {
        display: none;
    }
`;

type LastImportType = "middleware" | "aadpAlarms";
type Props = {
    children?: ReactNode;
    lastImportType?: LastImportType;
    pageId?: number;
};

const lastImportTypeDataSourceMap: Record<LastImportType, DataSourceName> = {
    middleware: "LastImport_Middleware",
    aadpAlarms: "LastImport_AADP_Alarms",
};

const WidgetFrameTitleInformation = (props: Props) => {
    const { children, lastImportType, pageId } = props;

    const { title: widgetTitle } = useContext(WidgetFrameTitleContext);

    const lastImportDataSource = lastImportType ? lastImportTypeDataSourceMap[lastImportType] : undefined;
    const { data: lastImport } = useDataSource<[{ timestampUTC: string }]>(
        lastImportDataSource,
        {},
        {
            skip: !lastImportDataSource,
        }
    );

    const dateFormat = "DD/MM/YYYY HH:mm:ss [(UTC)]";
    const lastImportTime = lastImport?.[0]?.timestampUTC;

    const canShowTooltip = !!children || !!lastImportTime;

    return (
        <WidgetFrameTitleInformationStyled>
            <DocumenatationTrigger title={widgetTitle} pageId={pageId}>
                <WidgetFrameTitleInformationIcon icon={"lely-icon-info"} />
            </DocumenatationTrigger>
            {canShowTooltip && (
                <WidgetFrameTitleInformationTooltip className={TOOLTIP_CLASS}>
                    {children && <div>{children}</div>}
                    {lastImportTime && (
                        <WidgetFrameTitleInformationTooltipLastImport>
                            Last Updated: {dayjs(lastImportTime).format(dateFormat)}
                        </WidgetFrameTitleInformationTooltipLastImport>
                    )}
                </WidgetFrameTitleInformationTooltip>
            )}
        </WidgetFrameTitleInformationStyled>
    );
};

export { WidgetFrameTitleInformation };
